<template>
  <AppLoader v-if="isLoading || loader"></AppLoader>
  <div v-if="!loader">
    <div v-if="htmlData" v-html="htmlData"></div>
  </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { configuration } from "@/configurationProvider";
import axios from "axios";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";

export default {
  name: "viewInYourBrowserComponent",
  components: {
    kbutton: Button,
    AppLoader,
  },

  data() {
    return {
      htmlData: null,
    };
  },
  computed: {},
  mounted() {
    this.getHtmlData();
  },
  methods: {
    async getHtmlData() {
      this.loader = true;
      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Campaign/email-body-view-in-browser?UId=${this.$route.params.UId}`,
        method: "GET",
      })
        .then((response) => {
          this.loader = false;
          this.htmlData = response?.data?.data;
        })
        .catch((error) => {
          this.loader = false;
        });
    },

    cancel() {
      window.close();
    },
  },
};
</script>
<style type="text/css"></style>
